import { template as template_43f62516db7544959dd0258719b83ac8 } from "@ember/template-compiler";
const FKText = template_43f62516db7544959dd0258719b83ac8(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
