import { template as template_fdccb8908aea45deb50a768eb4a92f8f } from "@ember/template-compiler";
const FKLabel = template_fdccb8908aea45deb50a768eb4a92f8f(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
